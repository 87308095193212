const regions = {
  'centro-sul': 'Centro Sul',
  'noroeste': 'Noroeste',
  'oeste': 'Oeste',
  'leste': 'Leste',
  'norte-central': 'Norte Cen.',
  'centro-oriental': 'Centro Ori.',
  'norte-pioneiro': 'Norte Pio.',
  'sudeste': 'Sudeste',
  'sudoeste': 'Sudoeste',
  'centro-ocidental': 'Centro Oci.',
}

export default regions
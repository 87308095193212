import React from 'react'

const RecalculandoQuizz = () => {
  return (
    <div className="container-page quizz-recalculando">
      <div className="imagemdestaque">
        <img src="/imgs/aniversario-teatro-guaira.png" alt="banner" />
      </div>
      <div className="content">
        <div className="title-enquete">
          <h1>Aniversário do Teatro Guaíra</h1>
        </div>
        <div className="description">
          <p>
            Acompanhe trechos de apresentações dos grupos artísticos do Centro
            Cultural Teatro Guaíra (CCTG). Balé, G2, OSP e Escola de Dança de um
            jeito que você nunca viu. As gravações foram feitas com uma câmera
            360 para você subir ao palco com os artistas e apreciar!
          </p>
          <a href="/recalculando.xlsx" download>
            <button>Baixar planilha</button>
          </a>

          <p>
            Acompanhe trechos de apresentações dos grupos artísticos do Centro
            Cultural Teatro Guaíra (CCTG). Balé, G2, OSP e Escola de Dança de um
            jeito que você nunca viu. As gravações foram feitas com uma câmera
            360 para você subir ao palco com os artistas e apreciar!
          </p>

          <a href="recalculando.xlsx" download>
            <button>Baixar planilha</button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default RecalculandoQuizz

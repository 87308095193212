import styled from 'styled-components'

export const Container = styled.div`
  text-align: center;
  max-width: 300px;
  margin: 16px auto;
`

export const Titulo = styled.h1`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin: 24px 0 16px;
`

export const Mapa = styled.div`
  position: relative;
`

export const Pin = styled.img`
  position: absolute;
  left: 80%;
`

export const Botoes = styled.div`
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
`

const Botao = styled.button`
  height: 27px;
  width: 100%;
  border-radius: 8px;
  color: #fff;
  border: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: bold;
  box-shadow: 0 4px 4px 0 #00000040;

  :focus {
    outline: none;
  }
`

export const ConfiraNoMapa = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 27px;
  width: 100%;
  border-radius: 8px;
  color: #fff;
  border: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: bold;
  box-shadow: 0 4px 4px 0 #00000040;
  background-color: #307D3E;

  :hover, :visited {
    color: #fff;
  }
`

export const IndiqueUmaCidade = styled(Botao)`
  background-color: #0D642A;
`

export const Filtros = styled.div`
  margin: 20px 0 10px;
  display: flex;
  gap: 8px;
`

export const Regiao = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  height: 31px;
  padding: 0 15px;
  background-color: #707070;
  color: #FFF;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  border-radius: 8px;

  button {
    background-color: transparent;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    border: none;
    padding: 0;
  }
`

export const Pesquisar = styled.input`
  height: 31px;
  width: ${({ hasRegion }) => (hasRegion ? '60%' : '100%')}; 
  border: 1px solid #BCBCBC;
  border-radius: 8px;
  color: #707070;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  padding: 0 15px;
  background: url('/imgs/mapa-plug/search.svg') no-repeat right 10px center;
  background-size: 20px;

  ::placeholder {
    color: #BCBCBC;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
  }

  :focus {
    outline: none;
  }  
`

export const Cidades = styled.div`
  display: grid;
  gap: 5px;
`

export const Cidade = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 31px;
  width: 100%;
  border-radius: 8px;
  background-color: ${({ ativo }) => (ativo ? '#43A454' : '#307D3E')};
  padding: 0 15px;
  transition: all .3s;

  h2 {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #FFF;
    margin: 0;
  }
`

export const BotaoExpandir = styled.button`
  display: flex;
  background: transparent;
  border: none;
  padding: 0;
  transform: rotate(${({ ativo }) => (ativo ? '180deg' : '0deg')});
  transition: transform 0.3s ease-in-out;

  :focus {
    outline: none;
  }
`

export const ConteudoExpandido = styled.div`
  padding: 10px 5px;
  margin-top: 5px;
`

export const UltimaExibicao = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: #000;
  text-align: left;
  margin-left: 15px;
  margin-bottom: 5px;

  span {
    font-weight: normal;
  }
`

export const BotoesConteudoExpandido= styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
`

export const Link = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 27px;
  width: 100%;
  border-radius: 8px;
  color: #fff;
  border: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: bold;
  
  :hover, :visited {
    color: #fff;
  }
`

export const ConfiraMateria = styled(Link)`
  background-color: #307D3E;
`

export const AssistirPrograma = styled(Link)`
  background-color: #000;
`

export const Subtitulo = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 30px;
  line-height: 13px;
`

export const Formulario = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;

  input,
  textarea {
    border: 1px solid #99958D;
    border-radius: 8px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #99958D;
    padding: 5px 15px;
    background-color: transparent;

    ::placeholder {
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      font-weight: bold;
      color: #99958D;
    }

    :focus {
      outline: none;
    }
  }

  div {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
    
    button {
      height: 27px;
      width: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      border: none;
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
      font-weight: bold;
      color: #FFF;
      background-color: #99958D;

     &[type='submit'] {
      background-color: #307D3E;
     } 
    }
  }
`
